(function () {
    'use strict';

    bindhq.ns('mailpane');

    bindhq.mailpane.element = $('.mailpane');

    bindhq.mailpane.searchInput = $(
        '.query-group input[type=text]',
        bindhq.mailpane.element,
    );

    /**
     * @type {Number}
     */
    bindhq.mailpane.MAX_ATTACHMENTS_SIZE = 1024 * 1024 * 24;

    /**
     * @type {String}
     */
    bindhq.mailpane.isAttachmentsLoaded = 'mailpane-attachments-loaded';

    /**
     * @type {String}
     */
    bindhq.mailpane.isUploadInitialized = 'mailpane-upload-initialized';

    /**
     * @param {jQuery.Event} evt
     * @param {Object} ui
     */
    bindhq.mailpane.onDragDrop = function (evt, ui) {
        const anchor = $('a', evt.target);
        const emailId = ui.draggable.data('id');

        bindhq.mailpane.performAction(anchor, emailId);
    };

    /**
     *
     */
    bindhq.mailpane.onDragStart = function () {
        const self = bindhq.mailpane;
        const actions = $('.mailpane-dnd-actions', self.element).empty();
        const droppableConfig = {
            accept: '.message',
            hoverClass: 'hover',
            drop: _.partial(self.onDragDrop),
            tolerance: 'pointer',
        };
        const container = $('.btn-actions ul', self.element)
            .clone()
            .removeClass('dropdown-menu')
            .appendTo(actions);

        $('li', container).droppable(droppableConfig);

        $('body').addClass('mailpane-dragging');
    };

    /**
     *
     */
    bindhq.mailpane.onDragStop = function () {
        $('body').removeClass('mailpane-dragging');
    };

    /**
     * @param {jQuery} container
     */
    bindhq.mailpane.initDrag = function (container) {
        const self = bindhq.mailpane;
        const config = {
            handle: '.handle',
            revert: true,
            helper: 'clone',
            start: _.partial(self.onDragStart),
            stop: _.partial(self.onDragStop),
        };

        $('.message-draggable', container).draggable(config);
    };

    /**
     * @param {jQuery} submit
     * @param {Array} fields
     * @param {jQuery} form
     */
    bindhq.mailpane.onSend = function (submit, fields, form) {
        const self = bindhq.mailpane;
        const totalSize = self.attachmentsSize();

        if (totalSize > self.maxAttachmentsSize()) {
            $('.attachments-size-message', self.element).addTimedClass('error');
            return false;
        }

        if (form.valid()) {
            submit.progress('start');
        } else {
            return false;
        }
    };

    /**
     * @param {jQuery} submit
     */
    bindhq.mailpane.onSent = function (submit) {
        const self = bindhq.mailpane;

        self.sendComplete('sent', submit);
        self.clearCompose();

        bindhq.tabs.refresh('mailpane-sent');
    };

    /**
     *
     */
    bindhq.mailpane.clearCompose = function () {
        const self = bindhq.mailpane;

        $('.send-form .ajax-select')
            .select2('data', [])
            .find('.select2-search-choice')
            .remove();

        $('.send-form', self.element).get(0).reset();

        $('.send-form .recipients-to').select2('val', null);

        $('.send-form .attachments .attachment', self.element).remove();

        $('.send-form .attachments', self.element).trigger('changed');

        $('.files-available', self.element).data(
            self.isAttachmentsLoaded,
            false,
        );
        $('.files-available .listing-files', self.element).empty();
        $('#document-library-attachments', self.element).empty();

        $('.wysiwyg', self.element).redactor('set', '');
    };

    /**
     * @param {jQuery} submit
     */
    bindhq.mailpane.onSendFailed = function (submit, xhr) {
        const failedElement = $('.alert-failed', bindhq.mailpane.element);
        const defaultFailedMessage =
            failedElement.data('defaultFailedMessage') || failedElement.html();
        const failedMessage = xhr.responseJSON
            ? xhr.responseJSON.message
            : defaultFailedMessage;

        failedElement.data('defaultFailedMessage', defaultFailedMessage);
        failedElement.html(xhr.responseJSON.message || defaultFailedMessage);

        bindhq.mailpane.sendComplete('failed', submit);
    };

    /**
     * @param {String} statusClass
     * @param {jQuery} submit
     */
    bindhq.mailpane.sendComplete = function (statusClass, submit) {
        submit.progress('stop');

        $('.submit-group', bindhq.mailpane.element).addTimedClass(statusClass);
    };

    /**
     *
     */
    bindhq.mailpane.onSearch = function () {
        bindhq.mailpane.toggleSearching();
    };

    /**
     * @param {Object} data
     *
     * @return {Object}
     */
    bindhq.mailpane.toMessage = function (data) {
        return _.extend(data, {
            attachments: _.where(data.attachments, { type: 1 }),
        });
    };

    /**
     *
     */
    bindhq.mailpane.onSearchComplete = function (messages) {
        const self = bindhq.mailpane;
        const html = bindhq.util.template(bindhq.tpl.mailpane_messages, {
            messages: _.map(messages, self.toMessage),
        });
        const container = $('.panel-search .messages', self.element);

        container.empty().html(html).css('scrollTop', 0);

        self.initDrag(container);

        $('li', container).on('click', self.onMessageClicked);
    };

    /**
     *
     */
    bindhq.mailpane.toggleSearching = function () {
        bindhq.mailpane.searchInput.progress('toggle');
    };

    /**
     * @param {String} tab
     * @param {Function} callback
     */
    bindhq.mailpane.show = function (tab, callback) {
        bindhq.tabs.show('mailpane-' + tab, callback);

        $('body').addClass('mailpane-open').trigger('mailpane:show');
    };

    /**
     * @param {String} subject
     * @param {String} body
     * @param {Array} recipients
     * @param {Array} attachments
     * @param {String} templateUrl
     * @param {boolean} sendMultiple
     */
    bindhq.mailpane.showCompose = function (
        subject,
        body,
        recipients,
        attachments,
        templateUrl,
        sendMultiple,
    ) {
        const self = bindhq.mailpane;

        self.clearCompose();

        $('#email_subject', self.element).val(subject || '');

        const message = $('#email_message', self.element);

        if (message.redactor) {
            message.redactor('set', body || '');
        } else {
            message.val(body || '');
        }

        $('#email_recipients', self.element).select2('data', recipients || []);

        $('#email_send_multiple', self.element).prop(
            'checked',
            sendMultiple || false,
        );

        _.each(attachments || [], self.addFile);

        if (templateUrl) {
            bindhq.emailTemplates.populateForm(self.element, templateUrl);
        }

        self.show('compose');
    };

    /**
     *
     */
    bindhq.mailpane.close = function () {
        $('body').removeClass('mailpane-open');
    };

    /**
     *
     */
    bindhq.mailpane.onSuggest = function () {
        const query = $(this).data('query');

        $('.search-form input[type=text]', bindhq.mailpane.element).val(query);

        $('.search-form').submit();
    };

    /**
     * @param {jQuery} container
     * @param {Object} data
     */
    bindhq.mailpane.onMessageLoaded = function (container, data) {
        const self = bindhq.mailpane;
        const html = bindhq.util.template(bindhq.tpl.mailpane_message, {
            message: self.toMessage(data),
        });

        container.html(html);

        $('.markup-raw', container).initEach(bindhq.markup.initContainer);
    };

    /**
     * @param {jQuery} container
     */
    bindhq.mailpane.onMessageFailed = function (container) {
        const html = bindhq.util.template(
            bindhq.tpl.mailpane_message_failed,
            {},
        );

        container.html(html);
    };

    /**
     * @param {jQuery} container
     */
    bindhq.mailpane.onMessageComplete = function (container) {
        container.removeClass('loading');
    };

    /**
     *
     */
    bindhq.mailpane.onMessageClicked = function () {
        const self = bindhq.mailpane;
        const emailId = $(this).data('id');
        const container = $('.reading-pane .content', bindhq.mailpane.element);

        container.empty().data('emailId', emailId).css('scrollTop', 0);

        container.addClass('loading');

        $.ajax({
            url: self.element.data('json-path').replace(/{emailId}/, emailId),
            success: _.partial(self.onMessageLoaded, container),
            error: _.partial(self.onMessageFailed, container),
            complete: _.partial(self.onMessageComplete, container),
        });

        $('.panel-search', bindhq.mailpane.element).addClass('panel-slid');
    };

    /**
     *
     */
    bindhq.mailpane.onReadingPaneCancel = function () {
        $('.panel-search', bindhq.mailpane.element).removeClass('panel-slid');
    };

    /**
     * @return {Array}
     */
    bindhq.mailpane.filesAttached = _.partial(
        bindhq.files.select,
        '.mailpane .attachments .collection-item',
    );

    /**
     *
     */
    bindhq.mailpane.onActionClick = function (evt) {
        const anchor = $(evt.currentTarget);
        const emailId = $(
            '.reading-pane .content',
            bindhq.mailpane.element,
        ).data('emailId');

        bindhq.mailpane.performAction(anchor, emailId);
    };

    /**
     * @param {jQuery} anchor
     * @param {Number} emailId
     */
    bindhq.mailpane.performAction = function (anchor, emailId) {
        const source = anchor.data('source');
        const url = source.replace(':emailId', emailId);
        const refreshTabs = anchor.data('refreshtabs');

        if (refreshTabs) {
            const itemActionId = bindhq.itemActions.handle({
                type: 'loading',
                item: {
                    title: 'Importing email',
                },
            });

            const config = {
                type: 'POST',
                url: url,
                success: function (data) {
                    const tabIds = refreshTabs.split(',');

                    bindhq.tabs.refreshAll(tabIds);
                    bindhq.itemActions.update(itemActionId, data);
                },
                error: function (xhr) {
                    bindhq.itemActions.update(itemActionId, {
                        type: 'error',
                        item: {
                            title: xhr.responseJSON
                                ? xhr.responseJSON.message
                                : 'Failed to import email',
                        },
                    });

                    throw new Error('Could not import email via URL: ' + url);
                },
            };

            $.ajax(config);
        } else {
            bindhq.loader.show();

            $('<form></form>')
                .attr('action', url)
                .attr('method', 'POST')
                .css('display', 'none')
                .appendTo('body')
                .submit();
        }
    };

    /**
     * @param {jQuery} fragment
     */
    bindhq.mailpane.onDelete = function (fragment) {
        const attachments = fragment.closest('.attachments');
        const onComplete = function () {
            attachments.trigger('changed');
        };

        fragment.fadeAndRemove(onComplete);
    };

    /**
     * @param {Object} file
     */
    bindhq.mailpane.addFile = function (file) {
        const self = bindhq.mailpane;
        const control = $('#email_attachments', self.element);
        const fileCount = $('input', control).length;
        const html = control
            .data('prototype')
            .replace(/__name__label__/g, file.name)
            .replace(/__name__/g, fileCount);
        const fragment = $(html);
        const onAttachmentDelete = bindhq.util.noDefault(
            _.partial(self.onDelete, fragment),
        );

        bindhq.files.withData(fragment, file);

        $('.file-id', fragment).val(file.id || '');

        $('.source', fragment).val(file.source || '');

        $('.name', fragment).val(file.name || '');

        $('.delete', fragment).click(onAttachmentDelete);

        if (file.input) {
            fragment.find('input').remove();
            fragment.append(file.input);
        }

        control.append(fragment).closest('.attachments').trigger('changed');
    };

    /**
     * @param {jQuery.Event} evt
     */
    bindhq.mailpane.onSlideToggle = function (evt) {
        $(evt.currentTarget).parents('.panel').toggleClass('panel-slid');
    };

    /**
     * @param {jQuery.Event} evt
     */
    bindhq.mailpane.onAvailableFileClick = function (evt) {
        $(evt.currentTarget).closest('.file').toggleClass('selected');
    };

    /**
     * @param {Array} currentFiles
     * @param {Object} file
     *
     * @return {Object}
     */
    bindhq.mailpane.isSelectedFile = function (currentFiles, file) {
        const matches = _.where(currentFiles, file);

        return matches.length > 0 ? $.extend(file, { selected: true }) : file;
    };

    /**
     * @param {jQuery.Event} evt
     */
    bindhq.mailpane.onAddAttachmentsClick = function (evt) {
        const self = bindhq.mailpane;
        const container = $('.files-available', self.element);
        const onSelectAttachmentClick = _.partial(
            self.onSelectAttachmentClick,
            container,
        );

        if (!container.data(self.isAttachmentsLoaded)) {
            self.loadAttachments(container);
        }

        if (!container.data(self.isUploadInitialized)) {
            $('.btn-attach-wrap', self.element).on(
                'click',
                '.btn-attach',
                onSelectAttachmentClick,
            );
        }

        container.data(self.isAttachmentsLoaded, true);
        container.data(self.isUploadInitialized, true);

        self.onSlideToggle(evt);
    };

    /**
     * @param {jQuery} container
     * @param {jQuery.Event} evt
     */
    bindhq.mailpane.onAttachmentChosen = function (container, evt) {
        const input = $(evt.currentTarget);
        const filePath = input.val();
        const files = $('#file-attachments ul.listing-files', container);

        const file = {
            name: filePath.substring(filePath.lastIndexOf('\\') + 1),
            source: 'upload',
            size: window.FileReader ? input.get(0).files[0].size : 0,
        };

        const html = bindhq.util.template(bindhq.tpl.mailpane_file, {
            file: file,
        });

        $(html)
            .addClass('selected')
            .data('input', input.remove())
            .appendTo(files);
    };

    /**
     * @param {jQuery} container
     * @param {jQuery.Event} evt
     */
    bindhq.mailpane.onSelectAttachmentClick = function (container, evt) {
        const onChange = _.partial(
            bindhq.mailpane.onAttachmentChosen,
            container,
        );

        $('<input type="file" />')
            .attr('name', 'attachment[]')
            .addClass('hidden')
            .change(onChange)
            .insertAfter(container)
            .trigger('click');
    };

    /**
     * @return {Array}
     */
    bindhq.mailpane.localFilesAvailable = function () {
        const self = bindhq.mailpane;

        const available = bindhq.files.select('[data-file-attachment=yes]');
        const unique = bindhq.files.unique(available);
        const withSelected = _.partial(
            self.isSelectedFile,
            self.filesAttached(),
        );

        return _.map(unique, withSelected);
    };

    /**
     * @param {function} callback
     */
    bindhq.mailpane.onAttachmentsLoaded = function (callback) {
        const self = bindhq.mailpane;
        const url = self.element.data('application-attachments-url');

        if (url) {
            $.ajax({
                context: this,
                url: url,
                success: function (result) {
                    const self = bindhq.mailpane;
                    const attachments = bindhq.files.unique(result.attachments);
                    const withSelected = _.partial(
                        self.isSelectedFile,
                        self.filesAttached(),
                    );

                    callback(
                        _.map(attachments, withSelected),
                        '#file-attachments',
                    );

                    if (result.document_library_attachments) {
                        const documentLibraryAttachments = bindhq.files.unique(
                            result.document_library_attachments,
                        );

                        const availableDocumentLibraryAttachments = _.map(
                            documentLibraryAttachments,
                            withSelected,
                        );

                        callback(
                            availableDocumentLibraryAttachments,
                            '#document-library-attachments',
                        );
                    }
                },
            });
        } else {
            callback(self.localFilesAvailable());
        }
    };

    bindhq.mailpane.composeFromElement = function (
        element,
        availableAttachments,
    ) {
        const recipientData = element.data('mailpane-recipients') || '';
        const attachmentData = element.data('mailpane-attachments') || '';

        const recipients = _.map(
            recipientData.split(','),
            bindhq.mailpane.stringToRecipient,
        );

        const attachments = _.map(
            attachmentData.split(','),
            _.partial(bindhq.files.findIn, availableAttachments),
        );

        bindhq.mailpane.showCompose(
            element.data('mailpane-subject'),
            element.data('mailpane-message'),
            _.compact(recipients),
            _.compact(attachments),
            element.data('mailpane-template-url'),
        );
    };

    /**
     * @param {jQuery.Event} evt
     */
    bindhq.mailpane.onActionCompose = function (evt) {
        const element = $(evt.currentTarget);
        const composeFromElement = _.partial(
            bindhq.mailpane.composeFromElement,
            element,
        );

        bindhq.mailpane.onAttachmentsLoaded(composeFromElement);
    };

    bindhq.mailpane.loadAttachments = function (container) {
        const populateList = function (container, result, selector) {
            const element = $(selector, container);
            const data = { files: result };
            const html = bindhq.util.template(bindhq.tpl.mailpane_files, data);
            const fragment = $(html);

            element.empty().append(fragment);
        };

        bindhq.mailpane.onAttachmentsLoaded(populateList.bind(null, container));
    };

    /**
     *
     */
    bindhq.mailpane.clearAttachments = function () {
        $('.attachments .collection-item', bindhq.mailpane.element).remove();
    };

    /**
     * @return {Integer}
     */
    bindhq.mailpane.maxAttachmentsSize = function (container) {
        const self = bindhq.mailpane;

        return self.element.data('max-attachments-size') * (1024 * 1024);
    };

    /**
     * @return {Number}
     */
    bindhq.mailpane.attachmentsSize = function () {
        const files = bindhq.mailpane.filesAttached();
        const toTotal = function (acc, file) {
            return acc === -1 || file.size === -1 ? -1 : acc + file.size;
        };

        return _.reduce(files, toTotal, 0);
    };

    /**
     *
     */
    bindhq.mailpane.refreshAttachmentsSize = function () {
        const self = bindhq.mailpane;
        const totalSize = self.attachmentsSize();
        const container = $('.attachments-size', self.element)
            .empty()
            .removeClass('warning');

        if (totalSize === -1) {
            container.html('(unknown size)');
        } else {
            container.html(
                '(' + bindhq.files.bytesToDescription(totalSize) + ')',
            );
        }

        const maxSize = self.maxAttachmentsSize();

        if (totalSize > maxSize) {
            container.addClass('warning');
        }
    };

    /**
     *
     */
    bindhq.mailpane.onSaveAttachmentsClick = function () {
        const self = bindhq.mailpane;
        const files = bindhq.files.select('.files-available .selected');

        self.clearAttachments();

        _.each(files, self.addFile);

        $('.panel-compose', self.element).removeClass('panel-slid');
    };

    /**
     * @param {String} data
     *
     * @return {Object|null}
     */
    bindhq.mailpane.stringToRecipient = function (data) {
        if (data === '') {
            return null;
        }

        const parts = data.split(':');

        return {
            id: parts[0],
            name: parts.length > 1 && parts[1] ? parts[1] : parts[0],
        };
    };

    /**
     * @param {jQuery} readingPane
     */
    bindhq.mailpane.onSentEmailResendClick = function (readingPane, evt) {
        const self = bindhq.mailpane;
        const anchor = $(evt.currentTarget);
        const subject = anchor.data('mailpane-subject');
        const message = anchor.data('mailpane-message');

        const recipients = _.map(
            anchor.data('mailpane-recipients').split(','),
            self.stringToRecipient,
        );

        const attachments = _.map(
            $('.attachments li', readingPane),
            bindhq.files.elementToFile,
        );

        self.showCompose(subject, message, recipients, attachments);
    };

    /**
     * @param {jQuery} panel
     * @param {String} html
     */
    bindhq.mailpane.onSentEmailLoaded = function (panel, html) {
        const self = bindhq.mailpane;
        const readingPane = $('.reading-pane', panel).empty().append(html);
        const onResendClick = _.partial(
            self.onSentEmailResendClick,
            readingPane,
        );

        self.initContainer(readingPane);

        $('.markup-raw', readingPane).initEach(bindhq.markup.initContainer);

        $('.btn-actions .compose', readingPane).click(onResendClick);
    };

    /**
     * @param {jQuery} panel
     * @param {String} url
     */
    bindhq.mailpane.showSentEmail = function (panel, url) {
        const onSuccess = _.partial(bindhq.mailpane.onSentEmailLoaded, panel);
        const config = { url: url, success: onSuccess };

        panel.addClass('panel-slid');

        $('.reading-pane', panel)
            .empty()
            .append('<div class="content loading"></div>');

        $.ajax(config);
    };

    /**
     * @param {jQuery.Event} evt
     */
    bindhq.mailpane.onSentEmailClick = function (evt) {
        const self = bindhq.mailpane;
        const anchor = $(evt.currentTarget);
        const url = anchor.data('url');
        const panel = anchor.closest('.panel');

        self.showSentEmail(panel, url);
    };

    /**
     * @param {jQuery} container
     */
    bindhq.mailpane.initContainer = function (container) {
        const self = bindhq.mailpane;

        const onActionCompose = bindhq.util.noDefault(self.onActionCompose);

        const onSlideToggle = bindhq.util.noDefault(self.onSlideToggle);

        $('[data-panel=slide-toggle]', container).click(onSlideToggle);

        $('.mailpane-action-compose', container).click(onActionCompose);
    };

    /**
     * @param {Number} emailId
     */
    bindhq.mailpane.dispatchShowSentEmail = function (emailId) {
        const self = bindhq.mailpane;
        const onShown = function (container) {
            const panel = $('.panel', container);
            const url = '/secure/mail/sent/' + emailId;

            self.showSentEmail(panel, url);
        };

        self.show('sent', onShown);
    };

    /**
     *
     */
    bindhq.mailpane.dispatchAction = function () {
        const re = /mailpane:(\w+):(\d+)/;
        const parts = location.hash.match(re);

        if (parts) {
            switch (parts[1]) {
                case 'sent':
                    bindhq.mailpane.dispatchShowSentEmail(parts[2]);
                    break;
            }
        }
    };

    bindhq.mailpane.showRecipientsField = function (element) {
        const anchor = $(element);
        const id = '#' + anchor.data('target');
        anchor.click((e) => {
            e.preventDefault();
            $(id).slideDown();
            anchor.remove();
        });
    };

    /**
     *
     */
    bindhq.mailpane.init = function () {
        const self = bindhq.mailpane;

        const onComposeClick = bindhq.util.noDefault(
            _.partial(self.show, 'compose', bindhq.util.noop),
        );

        const onSentClick = bindhq.util.noDefault(
            _.partial(self.show, 'sent', bindhq.util.noop),
        );

        const onSearchClick = bindhq.util.noDefault(
            _.partial(self.show, 'search', bindhq.util.noop),
        );

        const onActionClick = bindhq.util.noDefault(self.onActionClick);

        const onAddAttachmentsClick = bindhq.util.noDefault(
            self.onAddAttachmentsClick,
        );

        const onSaveAttachmentsClick = bindhq.util.noDefault(
            self.onSaveAttachmentsClick,
        );

        const onSentEmailClick = bindhq.util.noDefault(self.onSentEmailClick);

        const searchAjaxConfig = {
            beforeSubmit: self.onSearch,
            success: self.onSearchComplete,
            complete: self.toggleSearching,
        };

        const sendSubmit = $('.send-form [type=submit]');

        const composeAjaxConfig = {
            beforeSubmit: _.partial(self.onSend, sendSubmit),
            success: _.partial(self.onSent, sendSubmit),
            error: _.partial(self.onSendFailed, sendSubmit),
        };

        const source = $('body').data('mailpane-source') || '';

        $('#email_recipients').data(
            'ajaxurl',
            '/secure/mail/recipients.json?source=' + source,
        );

        $('#email_ccRecipients').data(
            'ajaxurl',
            '/secure/mail/recipients.json?source=' + source,
        );

        $('#email_bccRecipients').data(
            'ajaxurl',
            '/secure/mail/recipients.json?source=' + source,
        );

        $('#email_source').val(source);

        $('.send-form', self.element).ajaxForm(composeAjaxConfig);

        $('.mailpane-compose a').click(onComposeClick);

        $('.mailpane-sent a').click(onSentClick);

        $('.mailpane-search a').click(onSearchClick);

        $('.close', self.element).click(self.close);

        $('.suggestions li', self.element).click(self.onSuggest);

        $('.search-form', self.element).ajaxForm(searchAjaxConfig);

        $('.reading-pane .btn-cancel', self.element).click(
            self.onReadingPaneCancel,
        );

        $('.attachments', self.element).on(
            'changed',
            self.refreshAttachmentsSize,
        );

        self.element.on(
            'click',
            '.files-available h3, .files-available svg',
            self.onAvailableFileClick,
        );

        const actionMenu = $(
            '.mailpane .reading-pane .btn-actions .dropdown-menu',
        );

        $('#mailpane-actions li').remove().appendTo(actionMenu);

        $('.reading-pane .dropdown-menu a', self.element).click(onActionClick);

        $('.btn-add-attachments', self.element).click(onAddAttachmentsClick);

        $('.btn-save-attachments', self.element).click(onSaveAttachmentsClick);

        $('.recipients-controls')
            .children()
            .each((i, e) => self.showRecipientsField(e));

        self.element.on('click', '.emails-sent .listing h3', onSentEmailClick);

        self.dispatchAction();
    };
})();
